<template>
<div class="page-center padded form-wrapper">

    <h1>Thank you so much!</h1>

    <p>
        We appreciate very much your help to keep us online.
    </p>

    <p>
        <router-link :to="{name:'home'}" class="w3-text-blue">
            <i class="fas fa-fw fa-arrow-left"></i> Go back to the Home page 
        </router-link>
    </p>
</div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>